<template>

  <h2>API keys</h2>

  <section>

    <template v-if="userAPIKeys.length === 0">
      You currently have no API keys.
      <button class="mt-6 small" @click="openAPIKeyRequestModal">Request API access</button>
    </template>

    <template v-else>

      <div
        :key="apiKey.id"
        v-for="(apiKey, index) in userAPIKeys"
      >
        <h5>{{ apiKey.name || `API key ${index + 1}` }}</h5>
        <ul>
          <li>
            <strong>API key:</strong>
            {{ apiKey.token }}
            <button class="copy-button link" @click="copyToken(apiKey.token)"><CopyIcon /></button>
          </li>
          <li><strong>Created:</strong> {{ formatTimestamp(apiKey.insertedAt) }}</li>
          <li><strong>Last used:</strong> {{ formatTimestamp(apiKey.accessedAt) || 'never' }}</li>
        </ul>
      </div>

    </template>

  </section>

</template>

<script>

  import { mapState } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import CopyIcon from '@/assets/icons/copy.svg'

  export default {
    components: {
      CopyIcon,
    },
    setup(props) {
      const { formatTimestamp } = useFilters()
      return { formatTimestamp }
    },
    computed: {
      ...mapState('user', ['userAPIKeys']),
    },
    methods: {
      openAPIKeyRequestModal(apiKey) {
        this.$store.dispatch('modals/OPEN_MODAL', { name: 'APIKeyRequestModal' })
      },
      copyToken(token) {
        try {
          navigator.clipboard.writeText(token)
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: 'API key copied!',
            type: 'success',
          })
        } catch (error) {
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: 'Could not copy API key. Your browser may not support this feature.',
            type: 'error',
          })
        }
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/account-page.styl'

  h5
    @apply flex
    @apply mb-3
    @apply items-center

  .copy-button
    svg
      @apply w-4
      @apply h-4
      @apply inline
      @apply text-primary-500

</style>
